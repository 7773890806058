import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { NgbDatepickerModule, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslocoModule } from "@ngneat/transloco";
import { NgxMaskModule } from "ngx-mask";
import { DatepickerComponent, FormFieldErrorComponent, HrSeparatorComponent, InputComponent, TextareaComponent } from ".";
import { provideUikitTranslocoScope } from "../utils";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot({ validation: false }),
    TranslocoModule,
    NgbDatepickerModule,
    NgbTooltipModule
  ],
  providers: [provideUikitTranslocoScope()],
  declarations: [DatepickerComponent, FormFieldErrorComponent, InputComponent, HrSeparatorComponent, TextareaComponent],
  exports: [DatepickerComponent, FormFieldErrorComponent, InputComponent, HrSeparatorComponent, TextareaComponent]
})
export class FormComponentsModule {}
