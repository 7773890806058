<div class="d-flex flex-row align-items-center" *transloco="let t">
  <div *ngIf="label" class="pe-3">{{ label }}</div>

  <div [classList]="groupClasslist" role="group">
    <a
      class="btn btn-outline-primary text-nowrap"
      *ngFor="let item of items"
      [class.active]="activeItem?.value === item.value"
      (click)="onClick(item)"
    >
      {{ t(item.label) }}
    </a>
  </div>
</div>
