import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ApiResponseModel } from "../models";
import { AppConfigService } from "./app-config.service";

@Injectable({
  providedIn: "root"
})
export class ApiService {
  constructor(public http: HttpClient, private confService: AppConfigService) {}

  public get<T>(url: string, params?: any): Observable<T> {
    url = this.getFullUrl(url);
    return this.http
      .get<ApiResponseModel<T>>(url, { params: ApiService.buildUrlSearchParams(params) })
      .pipe(map((resp) => resp?.data || (resp as unknown as T)));
  }

  public post<T>(url: string, data?: any, params?: any): Observable<T> {
    url = this.getFullUrl(url);
    return this.http.post<ApiResponseModel<T>>(url, data).pipe(map((resp) => resp?.data || (resp as unknown as T)));
  }

  public getTextByFullUrl(url: string): Observable<string> {
    return this.http.get(url, { responseType: "text" });
  }

  private static buildUrlSearchParams(params: any): HttpParams {
    let searchParams = new HttpParams();
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        searchParams = searchParams.append(key, params[key]);
      }
    }
    return searchParams;
  }

  private getFullUrl(url: string): string {
    return `${this.confService.apiUrl}/${url}`;
  }
}
