import { Route } from "@angular/router";
import { AppRoute } from "./constants";
import { BlankPageComponent } from "./features/blank-page.component";

export const appRoutes: Route[] = [
  {
    path: AppRoute.Home,
    pathMatch: "full",
    component: BlankPageComponent
  },
  {
    path: AppRoute.LoyaltyProgram,
    loadChildren: () => import("./features/loyalty-program/loyalty-program.module").then((m) => m.LoyaltyProgramModule)
  },
  {
    path: "**",
    redirectTo: AppRoute.Home
  }
];
