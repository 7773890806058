import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-input", // todo: update selector to `lib-input`
  templateUrl: "./input.component.html",
  styleUrls: ["./input.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default
})
export class InputComponent {
  @Input() classList = "form-group";
  @Input() labelClassList = "";
  @Input() errorClassList = "";
  @Input() icon = "";
  @Input() clickableIconWhenDisabled = false;
  @Input() label = "";
  @Input() placeholder = "";
  @Input() readonly = false;
  @Input() type = "text";
  @Input() mask = "";
  @Input() isRequired = false;
  @Input() control: FormControl | null = null;
  @Input() tooltip = "";

  @Input() set patterns(value: any) {
    if (value) {
      this.customMaskPatterns = value;
    }
  }

  @Output() iconClick = new EventEmitter();

  customMaskPatterns: any;

  onIconClick(): void {
    if (this.control && (this.control.enabled || this.clickableIconWhenDisabled)) {
      this.iconClick.emit();
    }
  }
}
