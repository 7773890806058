import { Injectable } from "@angular/core";
import { OAuthService } from "angular-oauth2-oidc";
import { BehaviorSubject, Observable } from "rxjs";
import { ProfileModel } from "../models";
import { RoleTypes } from "../models/role";
import { decodeToken } from "../utils/jwt-helper";

type UserProfileModel = ProfileModel | null | undefined;

@Injectable({
  providedIn: "root"
})
export class AccountService {
  private user$: BehaviorSubject<UserProfileModel> = new BehaviorSubject<UserProfileModel>(this.user);

  userChanged: Observable<UserProfileModel> = this.user$.asObservable();

  constructor(private oAuthService: OAuthService) {}

  get isLoggedIn(): boolean {
    return this.oAuthService.hasValidAccessToken();
  }

  get user(): UserProfileModel {
    if (this.accessToken) {
      return decodeToken(this.accessToken);
    }
    return undefined;
  }

  get userId() {
    let userId = null;
    if (this.isLoggedIn && this.user?.sub) {
      userId = +this.user.sub;
    }
    return userId;
  }

  // Used to access user information
  get idToken(): string {
    return this.oAuthService.getIdToken();
  }

  get accessToken(): string {
    return this.oAuthService.getAccessToken();
  }

  public isUserInRole(role: RoleTypes): boolean {
    return this.isLoggedIn && this.user?.role === role;
  }

  public refreshUser() {
    this.user$.next(this.user);
  }
}
